import { template as template_1ba2e770392a4a309722bd1a6b32f7b6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1ba2e770392a4a309722bd1a6b32f7b6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
