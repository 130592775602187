import { template as template_d0396ae0665a4a1189c3420a2b24c28a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_d0396ae0665a4a1189c3420a2b24c28a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
