import { template as template_af90c3c8ef0f4fdf8e9214b9c61e7cf8 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_af90c3c8ef0f4fdf8e9214b9c61e7cf8(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
