import { template as template_5ac3426d2fc84e5d9e39f85957530f62 } from "@ember/template-compiler";
const FKLabel = template_5ac3426d2fc84e5d9e39f85957530f62(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
