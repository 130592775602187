import { template as template_5b68bc99042441b4989acbf03b0a8d13 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_5b68bc99042441b4989acbf03b0a8d13(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_5b68bc99042441b4989acbf03b0a8d13(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_5b68bc99042441b4989acbf03b0a8d13(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
